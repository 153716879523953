import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Link, useLocation } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Layout from './Layout/layout';
import { ThemeProvider } from './CustomComponents/darkmode';
import ScrollToTop from './CustomComponents/ScrollToTop';
import './App.css';
import { ProjectsProvider } from './CustomComponents/projectsContext';
import LoadingSpinner from './CustomComponents/Loading/LoadingSpinner';
import DelayedFallback from './CustomComponents/Loading/DelayedFallback';

// Lazy-loaded components
const Home = lazy(() => import('./MainPages/Home'));
const About = lazy(() => import('./MainPages/About'));
const Gallery = lazy(() => import('./MainPages/gallery'));
const FAQ = lazy(() => import('./MainPages/faq'));
const Contact = lazy(() => import('./MainPages/contact'));
const ServiceDetail = lazy(() => import('./CustomComponents/serviceDetail'));
const ServicesHome = lazy(() => import('./MainPages/servicesHome'));
const TermsConditions = lazy(() => import('./MainPages/Legal/TermsConditions'));
const PrivacyPolicy = lazy(() => import('./MainPages/Legal/privacypolicy'));
const Unfound = lazy(() => import('./MainPages/404'));

const GoogleAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const gtag = (window as any).gtag || function () {};
    gtag('config', 'G-3VQM8H2ND5', {
      page_path: location.pathname,
    });
  }, [location.pathname]);

  return null;
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <ProjectsProvider>
        <BrowserRouter>
          <GoogleAnalytics />
          <ScrollToTop />
          <Suspense fallback={<DelayedFallback><LoadingSpinner /></DelayedFallback>}>
            <Routes>
              <Route element={<Layout />}>
                <Route path='/' element={<Home />} />
                <Route path='/about' element={<About />} />
                <Route path='/gallery' element={<Gallery />} />
                <Route path='/faq' element={<FAQ />} />
                <Route path='/services/:title' element={<ServiceDetail />} /> {/* Updated to use :title instead of :id */}
                <Route path='/services' element={<ServicesHome />} />
                <Route path='/contact' element={<Contact />} />
                <Route path='/privacypolicy' element={<PrivacyPolicy />} /> {/* Use the same path as in Routes */}
                <Route path='/termsandconditions' element={<TermsConditions />} />
                <Route path="*" element={<Unfound />} />
              </Route>
            </Routes>
          </Suspense>
          <CookieConsent
  location="bottom"
  buttonText="I understand"
  cookieName="myAwesomeCookieName"
  style={{ background: "#2C2F33", color: "#FFFFFF", padding: "16px" }}
  buttonStyle={{ 
    color: "#FFFFFF", 
    backgroundColor: "#26527D", // Updated to requested colour
    fontSize: "13px", 
    padding: "8px 16px", 
    border: "none", 
    cursor: "pointer", 
    transition: "background-color 0.3s" 
  }}
  buttonClasses="cookie-consent-button"
  expires={150}
>
  This website uses cookies to enhance the user experience.{" "}
  <Link 
    to="/privacypolicy" 
    aria-label="Read our Privacy Policy to understand how we use cookies and tracking" 
    className="hover:text-customBlue text-white"
  >
    Privacy Policy
  </Link>
</CookieConsent>
        </BrowserRouter>
      </ProjectsProvider>
    </ThemeProvider>
  );
}

export default App;
