export const services = [
  { 
    id: 1, 
    title: 'PROJECT PLANNING', 
    description: 'Comprehensive project planning for property renovations in Leicester, Nottingham, and across the East Midlands.', 
    content: `Renovating your home in Leicester, Nottingham, or the East Midlands? Lambert & Wright specialise in turning dreams into reality with expert property renovations. Our project planning services include property sourcing, local surveys, architectural design, and planning application assistance, ensuring a smooth process.\n\nHomeowners often find renovating their property in Leicester or Nottingham more practical than moving. With over 30 years of experience, we deliver trusted project management, working with top architects and tradesmen across the Midlands.\n\nOur services include:\n\n• Project Management: Expert oversight from start to finish.\n\n• Trusted Suppliers: Sourcing materials like timber and modern solutions.\n\n• Skilled Tradesmen: Plastering, roofing, and groundwork by seasoned professionals.\n\n• Financial Advice: Expert guidance for mortgages and renovation funding.\n\nGet started with the East Midlands' trusted renovation experts.`,
    image: '/images/ServicesData/projectPlanning.webp',
  },
  { 
    id: 2, 
    title: 'BUILDING & CONSTRUCTION', 
    description: 'Expert building and construction services in Leicester, Nottingham, and the East Midlands.', 
    content: `Looking for trusted builders in Leicester or Nottingham? Lambert & Wright offer full building and construction services, ensuring quality finishes that meet all building regulations.\n\nOur team covers everything from timber framing and roofing to plastering and groundwork, specialising in both property developments and home renovations across the East Midlands.\n\nServices include:\n\n• Carpentry and Timber Framing\n\n• Roofing and Slate Work\n\n• Electrical and Plumbing Installations\n\n• Ground Works for New Developments\n\nTransform your property with Leicester’s leading building and construction team.`,
    image: '/images/ServicesData/Building&Construction.webp',
  },
  { 
    id: 3, 
    title: 'PLUMBING & ELECTRICAL', 
    description: 'Trusted plumbing and electrical services for homes in Leicester, Nottingham, and the Midlands.', 
    content: `Upgrade your home in Leicester or Nottingham with our expert plumbing and electrical services. Lambert & Wright specialise in heating, drainage systems, and electrical installations, ensuring your home is safe, efficient, and comfortable.\n\nOur certified plumbers and electricians provide:\n\n• Heating System Upgrades and Boiler Installations\n\n• Bathroom & Kitchen Plumbing\n\n• Hot/Cold Pipework Alterations\n\n• Electrical Design & Power Installations\n\n• Underfloor Heating and Radiator Systems\n\nChoose Lambert & Wright for top plumbing and electrical services in the Midlands.`,
    image: '/images/ServicesData/Plumbing&Electrical.png',
  },
  { 
    id: 4, 
    title: 'DESIGN', 
    description: 'Bespoke design services for kitchens, bathrooms, and interiors in Leicester, Nottingham, and the Midlands.', 
    content: `Lambert & Wright offer creative and functional design services for homes in Leicester and the surrounding Midlands. From stunning kitchens to modern bathroom designs, we bring your vision to life.\n\nOur design services include:\n\n• Interior Decorating and Custom Styling\n\n• Kitchen and Bathroom Designs\n\n• Flooring (Wooden Floors, Carpets)\n\n• Door and Window Upgrades (Bifold Doors, Velux Windows)\n\nFor bespoke designs tailored to your needs in Leicester or Nottingham, choose Lambert & Wright.`,
    image: '/images/ServicesData/Design.webp',
  },
  { 
    id: 5, 
    title: 'PROPERTY EXTENSIONS', 
    description: 'Specialists in home extensions in Leicester, Nottingham, and the East Midlands.', 
    content: `Need more space in your Leicester or Nottingham home? Lambert & Wright specialise in home extensions to create additional living areas, bedrooms, or kitchens.\n\nOur services include:\n\n• Kitchen Extensions\n\n• Bedroom and Living Area Additions\n\n• Planning Application Assistance\n\n• Full Project Management\n\nExtend your home with Leicester’s trusted renovation experts, serving the East Midlands.`,
    image: '/images/ServicesData/PropertyExtensions.webp',
  },
  { 
    id: 6, 
    title: 'LANDSCAPING', 
    description: 'Creative landscaping services for gardens and outdoor spaces in Leicester and the Midlands.', 
    content: `Transform your garden in Leicester or Nottingham with Lambert & Wright’s expert landscaping services. From patios and driveways to bespoke garden design, we deliver exceptional outdoor solutions.\n\nOur landscaping expertise includes:\n\n• Patios, Driveways, and Pathways\n\n• Garden Design and Maintenance\n\n• Water Features and Outdoor Lighting\n\n• Soft Landscaping (Lawns, Planting)\n\nCreate a beautiful outdoor space with our skilled Leicester landscaping team.`,
    images: [ 
      '/images/ServicesData/Landscaping1.webp',
      '/images/ServicesData/Landscaping2.webp',
    ]
  },
  { 
    id: 7, 
    title: 'BATHROOM & KITCHEN UPGRADES', 
    description: 'Bespoke bathroom and kitchen renovations in Leicester, Nottingham, and across the East Midlands.', 
    content: `Upgrade your kitchen or bathroom with Lambert & Wright, Leicester’s top choice for bespoke renovations. With over 45 years of experience, we specialise in creating stunning, functional spaces.\n\nOur services include:\n\n• Custom Worktops and Cabinetry\n\n• Tiling, Flooring, and Carpentry\n\n• Bathroom Fixtures (Showers, Baths, Toilets)\n\n• Kitchen Appliances and Storage Solutions\n\nFor high-quality kitchen and bathroom upgrades in Leicester or Nottingham, trust Lambert & Wright.`,
    images: [
      '/images/Bathrooms/Bath%201.webp',
      '/images/Bathrooms/Bath%202.webp',
      '/images/Bathrooms/Bath%206.webp',
    ]
  },
  { 
    id: 8, 
    title: 'HEATING INSTALLATION & UPGRADES', 
    description: 'Efficient heating systems for homes in Leicester, Nottingham, and the Midlands.', 
    content: `Stay warm and energy-efficient with heating installation and upgrades by Lambert & Wright. Serving Leicester, Nottingham, and the East Midlands, we specialise in underfloor heating, boiler installations, and radiator systems.\n\nOur heating services include:\n\n• Underfloor Heating Installation\n\n• Boiler System Upgrades\n\n• Radiator Installation and Repairs\n\n• Thermostat Installations\n\nChoose Lambert & Wright for expert heating solutions in the Midlands.`,
    image: '/images/ServicesData/heating.webp',
  },
];
